<template>
  <div id="tab-employee">
    <new-employee-modal :show="popupNewEmployee" title="Novo Funcionário"
      @action="refreshGrid(false)"
      @cancel="popupNewEmployee = false"></new-employee-modal>

    <div class="vx-card p-6">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ allData.total - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : allData.total }} de {{ allData.total }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="paginationSetSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Busca rápida por nome..." />
          <vs-button @click="handleNewRegistration" icon-pack="feather" icon="icon-user-plus" class="shadow-md mb-4 md:mb-0">Novo Funcionário</vs-button>
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :overlayNoRowsTemplate="noRowsTemplate"
        :localeText="localeText"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :cacheBlockSize="payload.limit"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />

    </div>
  </div>
</template>

<script>
import _ from 'lodash'
const newEmployeeModal = () => import('./newEmployeeModal.vue')

import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { localePt_Br } from '../../../../../assets/agGridTranslate/locale.pt_Br'

// Cell Renderer
import CellRendererName     from './cell-renderer/CellRendererName.vue'
import CellRendererPhones   from './cell-renderer/CellRendererPhones.vue'
import CellRendererRoles    from './cell-renderer/CellRendererRoles.vue'
import CellRendererActions  from './cell-renderer/CellRendererActions.vue'

import moduleEmployee       from '@/store/employee/moduleEmployee.js'

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    AgGridVue,
    CellRendererName,
    CellRendererPhones,
    CellRendererRoles,
    CellRendererActions,
    newEmployeeModal
  },
  data () {
    return {
      popupNewEmployee: false,

      searchQuery: '',

      payload: {
        offset: 0,
        limit: 40,
        searchQuery: null,
        params: null,
        consultApi: true
      },

      // AgGrid
      localeText: null,
      gridApi: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'Nome',
          field: 'name',
          width: 220,
          cellRendererFramework: 'CellRendererName'
        },
        {
          headerName: 'Funções',
          field: 'roles',
          width: 250,
          cellRendererFramework: 'CellRendererRoles'
        },
        {
          headerName: 'Email',
          field: 'email',
          width: 280
        },
        {
          headerName: 'Fones',
          field: 'phones',
          width: 210,
          cellRendererFramework: 'CellRendererPhones'
        },
        {
          headerName: 'Ações',
          field: 'actions',
          width: 95,
          sortable: false,
          cellRendererFramework: 'CellRendererActions'
          // cellRendererParams : {
          //   setDatasource : this.setDatasource.bind(this) // usado para passar parametros para dentro o componente renderizado no ag grid
          // }
        }
      ],
      components: {
        CellRendererName,
        CellRendererPhones,
        CellRendererRoles,
        CellRendererActions
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    allData () {
      return this.$store.state.employee.employeesGrid
    }
  },
  methods: {
    refreshGrid (consultApi) {
      this.payload.consultApi = consultApi
      this.gridApi.refreshInfiniteCache()
    },

    handleNewRegistration () {

      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_register_employee')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.popupNewEmployee = true
    },
    getSearchResult (searchVal) {
      this.payload.searchQuery = searchVal
      this.gridApi.setDatasource(this.dataSource)
    },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
    },
    onGridReady () {
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params

          if (self.payload.consultApi) await self.getAllData()
          self.payload.consultApi = true //esta linha faz com que a próxima chamada sempre seja feita para a API

          let lastRow = -1
          if (self.allData.total <= params.endRow) {
            lastRow = self.allData.total
          }
          params.successCallback(self.allData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },

    async getAllData () {
      this.gridApi.showLoadingOverlay()
      const data = await this.$store.dispatch('employee/fetchGrid', this.payload)
      this.gridApi.hideOverlay()
      if (!data.data.total) {
        this.gridApi.showNoRowsOverlay()
      }
      return data
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)
  }
}
</script>

<style>

</style>
