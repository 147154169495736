<template>
  <div class="flex items-center">
    <span v-for="(phone, i) in phones" :key="i" class="flex items-center">
      <a v-if="phone && phone.type === 'mobile' && phone.phone"
        :href="'https://api.whatsapp.com/send?phone=55'+celular.slice(0,2) + celular.slice(3)+'&text='" target="_blank">
          <img key="whatsappImg" :src="whatsappIcon" alt="Whatsapp" width="20" height="20" class="flex-shrink-0 cursor-pointer" />
      </a>
      <span class="ml-2">{{ i > 0 ? '| ' : ''}}{{ formatPhoneNumber(phone) }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'CellRendererPhones',
  data () {
    return {
      whatsappIcon: require('@/assets/images/whatsapp/icon/icon-whatsApp.png')
    }
  },
  computed: {
    phones () {
      if (this.params.value) {
        const phones = JSON.parse(JSON.stringify(this.params.value))
        //ORDENANDO OS TELEFONES NA SEQUÊNCIA ABAIXO
        const sortBy = ['mobile', 'phone', 'other']
        phones.sort((a, b) => sortBy.indexOf(a.type) - sortBy.indexOf(b.type))
        return phones
      }
      return []
    },
    // eslint-disable-next-line no-useless-escape
    celular () { return this.phones.find(phone => phone.type === 'mobile').phone.replace('(', '').replace(')', '').replace(/\-/g, '') }
  },
  methods: {
    formatPhoneNumber(phone) {
      return (phone && phone.phone && phone.phone !== "null") ? phone.phone : ''
    }
  }
}
</script>
