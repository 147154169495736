<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    }
  },

  methods: {
    editRecord () {
      this.$router.push(`/employee/employee-edit/${this.params.data.id}`).catch(() => {})
    },
    confirmDeleteRecord () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_register_employee')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('delete'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: `Tem certeza que deseja excluir \n "${this.params.data.name}"?`,
        accept: this.deleteRecord,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    deleteRecord () {
      this.$store.dispatch('employee/delete', this.params.data.id)
        .then(()   => {
          // this.params.setDatasource();// assim chamo a função vinda de fora
          this.params.api.refreshInfiniteCache()
          this.showDeleteSuccess()
        })
        .catch(err => { console.error(err) })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'SUCESSO',
        text: 'Dados excluídos com sucesso',
        iconPack: 'feather',
        icon: 'icon-check'
      })
    }
  }
}
</script>
