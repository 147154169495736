var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex items-center" },
    _vm._l(_vm.phones, function (phone, i) {
      return _c("span", { key: i, staticClass: "flex items-center" }, [
        phone && phone.type === "mobile" && phone.phone
          ? _c(
              "a",
              {
                attrs: {
                  href:
                    "https://api.whatsapp.com/send?phone=55" +
                    _vm.celular.slice(0, 2) +
                    _vm.celular.slice(3) +
                    "&text=",
                  target: "_blank",
                },
              },
              [
                _c("img", {
                  key: "whatsappImg",
                  staticClass: "flex-shrink-0 cursor-pointer",
                  attrs: {
                    src: _vm.whatsappIcon,
                    alt: "Whatsapp",
                    width: "20",
                    height: "20",
                  },
                }),
              ]
            )
          : _vm._e(),
        _c("span", { staticClass: "ml-2" }, [
          _vm._v(
            _vm._s(i > 0 && phone && phone.phone ? "| " : "") +
              _vm._s(_vm.formatPhoneNumber(phone))
          ),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }