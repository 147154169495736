<template>
  <div id="tab-employee">
    <new-registration-modal :show="popupNewRegistration" title="Novo Horário Semanal"
      @cancel="popupNewRegistration = false"></new-registration-modal>

    <div class="vx-card p-6">

      <div class="vx-col w-full mb-4 border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center">
        <h5 class="mb-1">Horários Semanais</h5>
        <vs-button @click="handleNewRegistration" icon-pack="feather" icon="icon-plus-circle" class="shadow-md mb-4 md:mb-2">Novo Horário</vs-button>
      </div>

      <vs-table v-for="(scheduleTime, i) in scheduleTimes" :key="`${i}_scheduleTime`" noDataText="Nenhum Horário Cadastrado" :data="scheduleTime.weekdays" hoverFlat stripe style="overflow: -webkit-paged-y">
        <template slot="thead">
          <vs-th colspan="4"><h5>{{ scheduleTime.schedule_type | scheduleType }}</h5></vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr style="opacity: 1 !important">
            <vs-td>Dia da Semana</vs-td>
            <vs-td>Horário</vs-td>
            <vs-td>Ações</vs-td>
          </vs-tr>
          <vs-tr :key="i" v-for="(weekdayTime, i) in data" style="opacity: 1 !important">
            <vs-td>
              <span>{{ weekDays[weekdayTime.charAt(0)] }}</span>
            </vs-td>
            <vs-td>
              <span>{{ weekdayTime.substr(2) }}</span>
            </vs-td>
            <vs-td>
              <vx-tooltip text="Excluir Horário Semanal" position="left">
                <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDelete(scheduleTime, weekdayTime)" />
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
const newRegistrationModal = () => import('./newRegistrationModal.vue')

import moduleScheduleTime      from '@/store/schedule-time/moduleScheduleTime.js'

export default {
  components: {
    newRegistrationModal
  },
  data () {
    return {
      popupNewRegistration: false,
      popupEditRegistration: false,
      weekDays: ['DOMINGO', 'SEGUNDA', 'TERÇA', 'QUARTA', 'QUINTA', 'SEXTA', 'SÁBADO'],
      toDelete: {
        scheduleTime: {},
        weekdayTime: null
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    scheduleTimes () {
      return this.$store.state.scheduleTime.scheduleTimes
    }
  },
  methods: {
    confirmDelete (scheduleTime, weekdayTime) {
      this.toDelete.scheduleTime = scheduleTime
      this.toDelete.weekdayTime = weekdayTime

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: 'Tem certeza que deseja efetuar esta exclusão?',
        accept: this.delete,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    delete () {
      this.$store.dispatch('scheduleTime/deleteWeekdayTime', this.toDelete)
        .then(()   => {
          this.showDeleteSuccess()
        })
        .catch(err => { console.error(err) })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'SUCESSO',
        text: 'Dados Excluídos',
        iconPack: 'feather',
        icon: 'icon-check'
      })
    },

    handleNewRegistration () {
      this.popupNewRegistration = true
    }
  },
  mounted () {

  },
  created () {
    if (!moduleScheduleTime.isRegistered) {
      this.$store.registerModule('scheduleTime', moduleScheduleTime)
      moduleScheduleTime.isRegistered = true
    }
    this.$store.dispatch('scheduleTime/fetchAll', this.type).then(() => { }).catch(err => { console.error(err) })
  }
}
</script>

<style>

</style>
