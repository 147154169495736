var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex items-center" },
    _vm._l(_vm.roles, function (role, i) {
      return _c("span", { key: i, staticClass: "flex items-center" }, [
        _c("span", { staticClass: "ml-2" }, [
          _vm._v(_vm._s(i > 0 ? "| " : "") + _vm._s(role && role.description)),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }