var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "page-registrations" } },
    [
      _c("vx-card", [
        _c(
          "div",
          {
            staticClass: "tabs-container px-6 pt-6",
            attrs: { slot: "no-body" },
            slot: "no-body",
          },
          [
            _c(
              "vs-tabs",
              {
                staticClass: "tab-action-btn-fill-conatiner",
                model: {
                  value: _vm.activeTab,
                  callback: function ($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab",
                },
              },
              [
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Funcionários",
                      "icon-pack": "feather",
                      icon: "icon-user",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [
                        _c("employee-tab", {
                          ref: "employee-tab",
                          staticClass: "mt-4",
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Médico/Psicólogo",
                      "icon-pack": "feather",
                      icon: "icon-activity",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("doctor-tab", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "CACs",
                      "icon-pack": "feather",
                      icon: "icon-clipboard",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("cac-tab", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Fornecedores",
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("provider-tab", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Grupos",
                      "icon-pack": "feather",
                      icon: "icon-grid",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("group-tab", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Horários de Exames",
                      "icon-pack": "feather",
                      icon: "icon-clock",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("schedule-exam-time-tab", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }