<template>
  <div class="flex items-center">
    <span v-for="(role, i) in roles" :key="i" class="flex items-center">
      <span class="ml-2">{{ i > 0 ? '| ' : ''}}{{ role && role.description }}</span>
    </span>
  </div>

</template>

<script>
export default {
  name: 'CellRendererRoles',
  computed: {
    roles () {
      return this.params.value
    }
  }
}
</script>
