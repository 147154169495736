<template>
  <div id="page-registrations">
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Funcionários" icon-pack="feather" icon="icon-user">
            <div class="tab-text">
              <employee-tab class="mt-4" ref="employee-tab" />
            </div>
          </vs-tab>
          <vs-tab label="Médico/Psicólogo" icon-pack="feather" icon="icon-activity">
            <div class="tab-text">
              <doctor-tab class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="CACs" icon-pack="feather" icon="icon-clipboard">
            <div class="tab-text">
              <cac-tab class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Fornecedores" icon-pack="feather" icon="icon-dollar-sign">
            <div class="tab-text">
              <provider-tab class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Grupos" icon-pack="feather" icon="icon-grid">
            <div class="tab-text">
              <group-tab class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Horários de Exames" icon-pack="feather" icon="icon-clock">
            <div class="tab-text">
              <schedule-exam-time-tab class="mt-4" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import EmployeeTab          from './tabs/Employee/Employee.vue'
import DoctorTab            from './tabs/Doctor/Doctor.vue'
import CacTab               from './tabs/CAC/Cac.vue'
import ProviderTab          from './tabs/Provider/Provider.vue'
import GroupTab             from './tabs/Group/Group.vue'
import ScheduleExamTimeTab  from './tabs/ScheduleExamTime/ScheduleExamTime.vue'

export default {
  components: {
    EmployeeTab,
    DoctorTab,
    CacTab,
    ProviderTab,
    GroupTab,
    ScheduleExamTimeTab
  },
  data () {
    return {
      activeTab: 0
    }
  }
}
</script>
<style lang="scss">
#page-student-edit {
  .vs-tabs--content {
    padding: 0 10px 23px !important;
  }
}
</style>
